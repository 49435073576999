@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./assets/styles/breakpoints.scss";
@import "./assets/styles/helpers.scss";
@import "./assets/styles/theme.scss";

:root {
  --color-mode: 'light';
  --expert-color: 24, 93, 170;
  --insured-color: 126, 237, 148;
  --guest-color: 255, 65, 76;
  --positive-color: 76, 175, 80;
  --negative-color: 211, 47, 47;
}

html,
body {
  padding: 0;
  margin: 0;
  border: 0;
  width: 100vw;
  min-width: 280px;
  min-height: 100vh;
  background-color: mat-color($light-primary, 300);

  .loader-container .loader .path {
    stroke: mat-color($light-accent, 900);
  }

  .app-logo {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("/assets/images/logo-light.png");
  }

  .bordered-container {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
}

@include angular-material-theme($light-theme);

@media(prefers-color-scheme: dark) {
  :root {
    --color-mode: 'dark';
  }

  :root:not([data-user-color-scheme]) {
    html,
    body {
      background-color: mat-color($dark-primary, 300);

      .app-logo {
        background-image: url("/assets/images/logo-dark.png");
      }

      .bordered-container {
        border: 1px solid rgba(255, 255, 255, 0.12);
      }
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-thick {
      color: rgba(255, 255, 255, 0.38);
    }

    @include angular-material-theme($dark-theme);
  }
}

[data-user-color-scheme='dark'] {
  html,
  body {
    background-color: mat-color($dark-primary, 300);

    .app-logo {
      background-image: url("/assets/images/logo-dark.png");
    }

    .bordered-container {
      border: 1px solid rgba(255, 255, 255, 0.12);
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: rgba(255, 255, 255, 0.38);
  }

  @include angular-material-theme($dark-theme);
}

a,
a:link,
a:visited,
a:hover,
a:focus,
a:active {
  color: inherit;
  text-decoration: none;
}

* {
  -webkit-user-drag: none;
}

.mat-form-field.no-padding > .mat-form-field-wrapper {
  margin-bottom: -1.25em;
}

.card-page {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  mat-card {
    display: flex;
    flex-direction: row;
    min-width: 280px;
    max-width: 400px;
    margin: 16px;

    & > div {
      min-height: 100%;
    }
  }

  @media only screen and (min-width: 600px) {
    & {
      flex-direction: row!important;
      background-image: url("/assets/images/background.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
    }
  }

  @media only screen and (max-width: 599px) {
    & {
      flex-direction: column!important;

      mat-card {
        flex-grow: 1!important;
        min-width: 100%!important;
        max-width: 100%!important;
        width: 100%!important;
        min-height: 100%!important;
        height: auto!important;
        padding: 0;
        margin: 0;
        border-radius: 0;

        & > div {
          min-height: 100%!important;
        }
      }
    }
  }
}

button.tall {
  @media only screen and (max-width: 599px) {
    & {
      height: 48px;
    }
  }
}

.warning {
  color: red;

  &.big {
    font-weight: bold;
    font-size: large;
  }
}

.attention {
  color: orange;

  &.big {
    font-weight: bold;
    font-size: large;
  }
}

.cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 500ms ease-in-out;
  &.cdk-drag-dragging {
    opacity: 0.5;
  }
}

.OT_widget-container {
  background-color: #202020!important;
}

app-secondary,
app-secondary > div,
app-secondary .OT_widget-container,
app-secondary video {
  border-radius: 5px;
}

mat-icon[matPrefix] {
  margin-right: 8px;
}